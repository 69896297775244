<template>
    <div id="events-history">
        <v-container>
            <!-- header -->
            <v-row>
                <v-col>
                    <h1 class="text-h5 font-weight-medium">Historial de Eventos</h1>
                    <p class="font-weight-medium text--secondary">Eventos Anteriores: {{ total }}</p>
                </v-col>
            </v-row>

            <!-- loading -->
            <v-card flat v-if="items.length === 0">
                <v-card-text class="text-center">
                    <v-progress-circular indeterminate color="primary"/>
                </v-card-text>
            </v-card>

            <!-- items -->
            <template v-for="(item, i) in items">
                <v-card flat class="mb-3 custom-card" :key="i" :to="{name: 'MyEvent', params: {uid: item.uid}}">
                    <v-card-text class="pa-1 px-0">
                        <v-row no-gutters align="center">
                            <v-col>
                                <v-list-item>
                                    <v-list-item-avatar color="grey lighten-3" class="rounded-sm flex-column" size="51">
                                        <h4 class="text-h6" style="line-height: 1">{{ item.startDate | dayNumber }}</h4>
                                        <h4 class="text-body-2 text-uppercase font-weight-medium">
                                            {{ item.startDate | dayName }}</h4>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="pa-0">
                                        <v-list-item-subtitle>Evento</v-list-item-subtitle>
                                        <v-list-item-title class="font-weight-medium">{{
                                            item.name
                                            }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.mobile">
                                <v-list-item>
                                    <v-list-item-content class="pa-0">
                                        <v-list-item-subtitle>Inicio</v-list-item-subtitle>
                                        <v-list-item-title class="text-body-2">
                                            {{ item.startDate | format }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.mobile">
                                <v-list-item>
                                    <v-list-item-content class="pa-0">
                                        <v-list-item-subtitle>Termino</v-list-item-subtitle>
                                        <v-list-item-title class="text-body-2">
                                            {{ item.finishDate | format }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>

            <!-- pagination -->
            <v-card flat class="mt-4">
                <v-card-text class="pa-2">
                    <v-btn icon @click="prevPage" :disabled="page === 1 || loadingPrev" :loading="loadingPrev">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <span class="mx-3">{{ `${page} / ${pages}` }}</span>
                    <v-btn icon @click="nextPage" :disabled="page === pages || loadingNext" :loading="loadingNext">
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import {db} from "@/firebase";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  orderBy,
  getCountFromServer,
  startAfter,
  endBefore,
  limitToLast
} from "firebase/firestore";
import moment from "moment";

export default {
  name: "EventsPast",

  data: () => ({
    page: 1,
    pages: 0,
    limit: 10,
    total: 0,
    items: [],
    lastVisible: null,
    firstVisible: null,
    loading: false,
    loadingNext: false,
    loadingPrev: false
  }),

  filters: {
    format: (value) => {
      if (!value) return
      const date = value.toDate();
      return moment(date).format('DD MMMM YYYY, h:mm:ss a')
    },

    dayNumber: (value) => {
      if (!value) return
      const date = value.toDate();
      return moment(date).format('DD')
    },

    dayName: (value) => {
      if (!value) return
      const date = value.toDate();
      const day = moment(date).format('ddd')
      return day.replace('.', '')
    }
  },

  methods: {
    async onInit(id) {

      const collectionRef = collection(db, `companies/${id}/events`);

      // count
      const q = query(collectionRef, where("finishDate", "<=", new Date()),);
      const snapshot = await getCountFromServer(q);
      this.total = snapshot.data().count
      this.pages = Math.ceil(this.total / this.limit);

      // get items
      const first = query(collectionRef, where("finishDate", "<=", new Date()), orderBy("finishDate"), limit(this.limit));
      const documentSnapshots = await getDocs(first);

      this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];

      const items = []
      documentSnapshots.forEach((doc) => {
        items.push({uid: doc.id, ...doc.data()})
      });
      this.items = items;

    },

    async nextPage() {
      this.loadingNext = true;

      const collectionRef = collection(db, `companies/${this.$store.state.auth.user.companyId}/events`);
      const next = query(collectionRef, where("finishDate", "<=", new Date()), orderBy("finishDate"), startAfter(this.lastVisible), limit(this.limit));
      const documentSnapshots = await getDocs(next);

      this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
      this.firstVisible = documentSnapshots.docs[0];

      const items = []
      documentSnapshots.forEach((doc) => {
        items.push({uid: doc.id, ...doc.data()})
      });
      this.items = items;
      this.page++;
      this.loadingNext = false;
    },

    async prevPage() {
      this.loadingPrev = true;

      const collectionRef = collection(db, `companies/${this.$store.state.auth.user.companyId}/events`);
      const next = query(collectionRef, where("finishDate", "<=", new Date()), orderBy("finishDate"), endBefore(this.firstVisible), limitToLast(this.limit));
      const documentSnapshots = await getDocs(next);

      this.lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
      this.firstVisible = documentSnapshots.docs[0];

      const items = []
      documentSnapshots.forEach((doc) => {
        items.push({uid: doc.id, ...doc.data()})
      });
      this.items = items;
      this.page--;
      this.loadingPrev = false;
    }
  },

  mounted() {
    this.onInit(this.$store.state.auth.user.companyId)
  }
}
</script>

<style scoped lang="scss">
.custom-card {
  transition: all 0.2s ease-in-out !important;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.06) 0 1px 12px !important;
  }
}
</style>